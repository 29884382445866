import Vue from 'vue'

import VueRouter from 'vue-router'
import RangeSlider from 'vue-range-slider'

import Autocomplete from './components/vue2-autocomplete' // This is a custom fork from the autocomplete package

Vue.use(VueRouter)

var ProductTypes = Vue.component('product-types', {template: ''}),
	ProductGroupings = Vue.component('product-groupings', {template: ''}),
	Products = Vue.component('products', {template: ''}),
	z = 100,
	router;

"use strict";

// Get the viewport height and multiply it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then set the custom --vh value to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
});

function main(callback) {
	if (router.app.productTypes) {
			callback();
	} else {
			hijax('get', window.urlProductTypes,
					null, function (data) {
							router.app.productTypes = JSON.parse(data).items;
							callback();
					}
			);

			hijax('get', window.urlCart,
					null, function (data) {
							var i, n, l, cartIds = [], cart = JSON.parse(data), formFields = ['first_name', 'last_name', 'email', 'phone', 'organisation_nr', 'organisation_name', 'adress', 'zip', 'city', 'delivery_method_id'];
							router.app.cartCurrency = cart.item.pretty_currency;
							router.app.vatPercentage = parseInt(cart.item.vat_percentage);
							router.app.shippingCost = cart.item.shipping_cost;
							router.app.priceExclShipping = cart.item.price_excl_shipping;
							router.app.priceInclShipping = vatCheck(cart.item.price_incl_shipping);
							router.app.orderNumber = cart.item.order_number;

							for (i = 0, l = cart.item.items.length; i < l; i++) {
									n = cart.item.items[i];
									router.app.cartIds.push(n.article);
									router.app.chosenProducts.push(cart.item.items[i]);
							}

							for (i = 0, l = formFields.length; i < l; i++) {
									var ci = cart.item[formFields[i]];
									if (ci) {
										router.app['form_' + formFields[i]] = ci;
									}
							}

							const cart_year = cart.item.inserted.split('-')[0];
							const curr_year = new Date().getFullYear();
							if (cart_year != curr_year) {
									console.log('Empty basket');
									router.app.emptyCart();
							}
					}
			);
	}

	getProductCategoriesFromBackend();
	getProductTypesGroupsFromBackend();
	getBannersFromBackend();
	getSaleProductsFromBackend();
	getCampaignsFromBackend();
}

function hijax(method, uri, data, onSuccess, onError) {
	var csrftoken, client = new XMLHttpRequest();

	router.app.loading = true;

	if (onError == undefined) {
		function onError(status, response) {
					console.log(status);
					console.log(response);
			}
	}
	if (!router.app.hijaxers) {
			router.app.hijaxers = {};
	}
	router.app.hijaxers[uri.split('?')[0]] = client;
	client.open(method, uri, true);

	if (method.toLowerCase() == 'post') {
			client.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
	}

	if ('get head trace options'.indexOf(method.toLowerCase()) == -1) {
			csrftoken = parseCSRFToken();
			if (csrftoken) {
					client.setRequestHeader("X-CSRFToken", csrftoken);
			}
	}

	client.onload = function () {
			if (client.status >= 200 && client.status < 400) {
					onSuccess(client.responseText, client);
					// Add timeout to make the loading spinner time longer
					setTimeout(function(){
						router.app.loading = false;
					}, 500);
			} else {
					onError(client.status, client.responseText);
					router.app.loading = false;
			}

			delete router.app.hijaxers[uri];

			if (Object.keys(router.app.hijaxers).length == 0) {
					router.app.loading = false;
			}
	};

	client.send(data);
};

function parseCSRFToken() {
	var name = 'csrftoken';
	var cookieValue = null;
	if (document.cookie && document.cookie !== '') {
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
					var cookie = cookies[i].trim();
					if (cookie.substring(0, name.length + 1) === (name + '=')) {
							cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
							break;
					}
			}
	}
	return cookieValue;
};

function prepareFormData(temp) {
	var fields = [
			'first_name', 'last_name', 'email', 'phone', 'comment', 'tradeshow', 'accept_copy', 'organisation_nr',
			'organisation_name', 'adress', 'zip', 'city', 'invoice_first_name', 'invoice_last_name', 'invoice_email',
			'invoice_phone', 'invoice_organisation_nr', 'invoice_organisation_name', 'invoice_adress', 'invoice_zip',
			'invoice_city', 'delivery_method_id', 'campaign_answer'
		],
		data = [],
		i = fields.length;

	while (i--) {
			if (router.app['form_' + fields[i]]) {
					data.push(fields[i] + '=' + encodeURIComponent(router.app['form_' + fields[i]]));
			}
	}

	var vatval = 0.0;
	if (router.app.vatCost) {
		vatval = router.app.vatCost.replace(/[\.\s]/g, '');
	}
	data.push('vat_value' + '=' + encodeURIComponent(vatval));
	data.push('pdf_invoice' + '=' + encodeURIComponent(router.app.form_pdf_invoice));
	data.push('temp' + '=' + encodeURIComponent(temp));
	
	if (router.app.campaigns.length > 0) {
		data.push('campaign_question' + '=' + encodeURIComponent(router.app.campaigns[0].campaign_question));
	}

	return data.join('&');
};


// Common ajax calls
// ------------------------------------------------------------------------

function getOptionGroupsFromBackend(preset) {
	hijax('get',
			window.urlOptionGroups.replace('FIXME', router.app.chosenProductTypeSystem),
			null, function (data) {
					var jsonData = JSON.parse(data);

					if (jsonData.items.length) {
							router.app.optionGroups = jsonData.items;

							router.app.optionValues = {};
							router.app.optionWCValues = [];
							if (restoreQueryParams(preset)) {
									syncDisabledOptions();
									getProductsFromBackend();
							}
					}
			}
	);
};

function getProductCategoriesFromBackend() {
	hijax('get',
			window.urlProductCategories,
			null, function (data) {
					var jsonData = JSON.parse(data);

					if (jsonData.items.length) {
							router.app.productCategories = jsonData.items;
					}
			}
	);
};

function getProductTypesGroupsFromBackend(params) {
	var url = window.urlProductTypesGroups;
	if (typeof(params) != 'undefined') {
			if (params.hasOwnProperty('search')) {
					url = url + '?search=' + params['search'];
			}
	}

	hijax('get',
			url,
			null, function (data) {
					var jsonData = JSON.parse(data);

					if (jsonData.items.length) {
							router.app.productTypesGroups = jsonData.items;
					}
			}
	);
};

function getProductGroupsFromBackend() {
	if (router.app.displaySearch) {
			return false;
	}

	hijax('get',
			window.urlProductGroupings.replace('FIXME', router.app.chosenProductTypeSystem),
			null, function (data) {
					var jsonData = JSON.parse(data);

					if (jsonData.items.length) {
							router.app.productGroupings = jsonData.items;

							for (let i = 0; i < jsonData.items.length; i++) {
									if ((router.app.chosenProductGrouping == jsonData.items[i].base) && (jsonData.items[i].external_link != '')) {
								  		router.app.chosenProductGroupingExternalLink = jsonData.items[i].external_link;
									}
							}
					}
			}
	);
};

function getProductsFromBackend(appendAfter, filter_product_type, limit) {
	if (router.app.hijaxers['/products']) {
			router.app.hijaxers['/products'].abort();
			delete router.app.hijaxers['/products'];
	}
	var params = [], i, k, os, tt;

	os = router.app.optionsSet;

	delete os.limit;
	if (limit) {
			params.push('limit=' + limit);
	}

	for (k in os) {
			if (os.hasOwnProperty(k)) {
					params.push(k + '=' + os[k]);
			}
	}

	if (appendAfter != undefined) {
			params.push('offset=' + appendAfter);
	}

	if (app.displayPriceChange) {
			params.push('next_gross_price=1');
	}

	if (filter_product_type) {
			params.push('product_type=' + filter_product_type);
			params.push('product_type_skip=' + router.app.chosenGroupedProducts[filter_product_type].items.length);
	}

	hijax('get',
			window.urlProducts.replace("FIXME", (router.app.chosenProductTypeSystem ? router.app.chosenProductTypeSystem : 'all')) + '?' + params.join('&'),
			null,
			function (data, c) {
					var jsonData = JSON.parse(data),
							tt = document.querySelector('.tooltip'),
							append = c.responseURL.split('?')[1].match('offset');
					if (tt) {
							tt.classList.add('reveal');
					}
					if (jsonData.items.length) {
							if (append) {
									router.app.products = router.app.products.concat(jsonData.items);
							} else {
									router.app.products = jsonData.items;
							}
					} else {
							if (!append) {
									router.app.products = [];
									router.app.aborted = true;
									router.app.displayAside = true;
							}
					}

					if (jsonData.models) {
							router.app.searchMatchModels = jsonData.models;
							var couplingDropdown = document.getElementById('bucket-coupling');
							if (couplingDropdown && couplingDropdown.value == jsonData.search) {
									couplingDropdown.value = null;
									couplingDropdown.classList.remove('set');
									delete router.app.optionValues['bucket-coupling'];
									delete router.app.optionsSet['bucket-coupling'];
									router.app.disabledOptions = [];
									syncDisabledOptions();
							}
					}

					router.app.productsTotal = jsonData.total ? parseInt(jsonData.total, 10) : 0;
					router.app.noMoreProducts = router.app.products ? router.app.products.length == router.app.productsTotal : false;
			}
	);

	return false;
};

function sendFormToBackend(url, data) {
	hijax('post', url, data, function (res) {
			router.app.success = true;
			window.scrollTo(0, 0);
			router.app.form_first_name = null;
			router.app.form_last_name = null;
			router.app.form_email = null;
			router.app.form_phone = null;
			router.app.form_comment = null;
			// Remember tradeshow until next time,
			// otherwise do this:
			// router.app.form_tradeshow = null;
			// router.app.form_tradeshowOptions = [];
			router.app.formErrors = [];
	}, function (code, res) {
			var errors = JSON.parse(res), fields = [];

			if ('email' in errors) {
					fields.push('email_required');
			}

			if ('phone' in errors) {
					fields.push('phone_required');
			}

			if ('first_name' in errors) {
					fields.push('name_required');
			}

			if ('last_name' in errors) {
					fields.push('last_name_required');
			}

			router.app.formErrors = fields;
	});
};

function syncDisabledOptions() {
	var o, i, params = [], os = router.app.optionsSet;

	for (o in os) {
			if (os.hasOwnProperty(o)) {
					params.push(o + '=' + encodeURIComponent(os[o]));
			}
	}

	if (!params.length) {
			return;
	}

	if (router.app.optionGroups) {
		i = router.app.optionGroups.length;
		while (i--) {
				o = router.app.optionGroups[i];
				hijax('get', o.autocomplete_url + '?meta=1&name=&' + params.join('&'), null, function (data, c) {
						var res = JSON.parse(data);

						if (!res.options_exists) {
								router.app.disabledOptions.push(c.responseURL.match(/options\/(.+)\?/)[1]);
						} else {
								router.app.disabledOptions = router.app.disabledOptions.filter(function (i) {
										return i != c.responseURL.match(/options\/(.+)\?/)[1];
								});
						}
				});
		}
	}
};

function getBannersFromBackend() {
	hijax('get',
		window.urlBanners,
		null, function (data) {
			var jsonData = JSON.parse(data);

			if (jsonData.items.length) {
				router.app.banners = jsonData.items;
				// console.log(router.app.banners);
			}
		}
	);
};

function getSaleProductsFromBackend() {
	hijax('get',
		window.urlSaleProducts,
		null, function (data) {
			var jsonData = JSON.parse(data);

			if (jsonData.items.length) {
				router.app.saleProducts = jsonData.items;
			}
		}
	);
};

function getCampaignsFromBackend() {
	hijax('get',
		window.urlCampaigns,
		null, function (data) {
			var jsonData = JSON.parse(data);

			if (jsonData.items.length) {
				router.app.campaigns = jsonData.items;
			}
		}
	);
};


// State setter functions
// ------------------------------------------------------------------------

function setProductGrouping(pname, cpg, groupingName, base) {
	var route = 'products',
			params = {slug: pname};

	router.app.chosenProductTypeSystem = pname;
	router.app.chosenProductGroupingName = cpg;
	router.app.chosenProductGrouping = groupingName ? groupingName : null;

	if (router.app.chosenProductGrouping) {
			route = 'gproducts';
			router.app.chosenProductType = pname;
			params.grouping = router.app.chosenProductGrouping + ':' + router.app.chosenProductGroupingName;
	}

	router.push({
			name: route,
			params: params,
			query: router.app.weight ? {weight: router.app.weight} : {}
	});
};

function setProductGroupingSearch(e) {
	e.preventDefault();

	if (!router.app.optionValues.hasOwnProperty('search')) {
		return false;
	}

	var searchObj = {
		name: router.app.optionValues['search'],
		slug: router.app.optionValues['search']
	};

	router.app.setOption(searchObj, { id: 'search' });
};

function setProductSearch(e) {
	e.preventDefault();

	if (!router.app.optionValues.hasOwnProperty('search')) {
		return false;
	}

	var os = router.app.optionsSet;

	for (const [key, value] of Object.entries(os)) {
			if (key != 'search') {
					delete os[key];
			}
	  	// console.log(`${key}: ${value}`);
	}

	router.app.chosenProductType = null;
	router.app.chosenProductTypeSystem = 'all';
	router.app.chosenProductGroupingName = null;
	router.app.chosenProductGrouping = null;

	// var searchObj = {
	// 	name: router.app.optionValues['search'],
	// 	slug: router.app.optionValues['search']
	// };

	// router.app.setOption(searchObj, { id: 'search' });

	router.push({name: 'products', params: {slug: 'all'}, query: os});
};

function vatCheck(price) {
	var vatCost;
	if (router.app.vatPercentage) {
		price = parseInt(price.replace(/[\.\s]/g, ''), 10);
		vatCost = parseInt(price * (router.app.vatPercentage/100));
		price = price + vatCost;
		router.app.vatCost = window.pretty(vatCost);
	}
	return window.pretty(price);
}

function vatPrice(price) {
	var vatCost;
	if (router.app.vatPercentage) {
		price = parseInt(price.replace(/[\.\s]/g, ''), 10);
		vatCost = parseInt(price * (router.app.vatPercentage / 100));
		price = price + vatCost;
	}
	return window.pretty(price);
}

function setSortOption(param) {
	if (param.sortby) {
		router.app.sortby = param.sortby;
	} else {
		router.app.sortby = 'availability';
	}
}


// State restoration functions
// ------------------------------------------------------------------------

function restoreProductType() {
	var values = window.location.pathname.split('/');

	if (!router.app.chosenProductType) {
			router.app.chosenProductType = restoreProductTypeName(values[1]);
			router.app.chosenProductTypeSystem = values[1];
	}
	if (!router.app.chosenProductGrouping) {
			if (values.length > 2) {
					router.app.chosenProductGrouping = values[2].split(':')[0];
					router.app.chosenProductGroupingName = values[2].split(':')[1];
			}
	}
};

function restoreProductTypeName(system) {
	if (!router.app.productTypes) {
			return;
	}

	var i = router.app.productTypes.length;

	while (i--) {
			if (router.app.productTypes[i].name == system) {
					router.app.chosenProductTypeHelpName = router.app.productTypes[i].help_sections.name;
					router.app.chosenProductTypeHelpDescription = router.app.productTypes[i].help_sections.description;
					router.app.chosenProductTypeHelpSections = router.app.productTypes[i].help_sections.items;
					router.app.chosenProductTypeInfoLink = router.app.productTypes[i].info_link;
					return router.app.productTypes[i].plural_title;
			}

	}
};

function restoreQueryParams(params) {
	var wc = [], o = {}, k, e;

	clearTimeout(window.wait);

	for (k in params) {
			if (params.hasOwnProperty(k)) {
					if (params[k] === '*') {
							wc.push(k);
					} else {
							o[k] = params[k];
					}
			}
	}

	router.app.optionWCValues = wc;
	router.app.optionValues = o;

	return true;
};

function restoreInputFilters() {
	// Create an array of all filter inputs
	const filterInputs = ['bucket-volume','bucket-width','bucket-coupling','bucket-teeth-option-group','search'];
	var ke, field, val;

	clearTimeout(window.wait);

	for (var i = 0; i < filterInputs.length; i++) {
			field = filterInputs[i];
			ke = document.getElementById(field);
			if (ke) {
					val = router.app.optionValues[field];
					if (val) {
							ke.value = val;
							ke.classList.add('set');
					} else {
							ke.value = '';
							ke.classList.remove('set');
							delete router.app.optionValues[field];
							delete router.app.optionsSet[field];
					}
			}
	}
}

function restoreWeightParam(params) {
	if (params.weight) {
			router.app.weight = parseFloat(params.weight);
	}
};


// Field focus functions
// ------------------------------------------------------------------------

function blurHilightedField() {
	var i, existing;

	existing = document.querySelectorAll('.is-field-hilighted');
	if (existing) {
			i = existing.length;
			while (i--) {
					existing[i].classList.remove('is-field-hilighted');
					existing[i].style.zIndex = '';
			}
	}

	router.app.fieldFocused = false;
};

router = new VueRouter({
	mode: 'history',
	routes: [
			{
					name: 'types',
					path: '/',
					component: ProductTypes,
					beforeEnter: function (to, from, next) {
							main(function () {
								var showCart = to.query.cart;
								console.log('/');

								router.app.displayCart = false;
								router.app.displayMenu = false;
								if (showCart == 'show') {
									router.app.displayCart = true;
									router.app.fromOpenCart = true;
								}

								router.app.displayCheckout = false;
								router.app.displayPayment = false;
								router.app.displayOrderConfirmation = false;
								router.app.displaySearch = true;
								router.app.displaySalesPage = false;
								router.app.chosenProductGrouping = null;
								router.app.chosenProductType = null;
								router.app.chosenProductTypeSystem = null;

								next();
							});
					}
			},
			{
					name: 'salespage',
					path: '/rea',
					beforeEnter: function (to, from, next) {
							main(function () {
									window.scrollTo(0, 0);

									router.app.displayCart = false;
									router.app.displayMenu = false;
									router.app.displayCheckout = false;
									router.app.displayPayment = false;
									router.app.displaySearch = false;
									router.app.displaySalesPage = true;
							});
					}
			},
			{
					name: 'groupings',
					path: '/:slug',
					component: ProductGroupings,
					beforeEnter: function (to, from, next) {
							main(function () {
									var url;
									window.scrollTo(0, 0);
									console.log('/:slug');
									restoreWeightParam(to.query);

									router.app.displayCart = false;
									router.app.displayMenu = false;
									router.app.displayCheckout = false;
									router.app.displayPayment = false;
									router.app.displayOrderConfirmation = false;
									router.app.displaySearch = false;
									router.app.displaySalesPage = false;
									router.app.optionGroups = null;
									router.app.products = null;
									router.app.chosenProductGrouping = null;
									router.app.chosenProductGroupingName = null;

									restoreProductType();
									setSortOption(to.query);

									if (!router.app.productGroupings) {
											url = window.urlProductGroupings.replace('FIXME', to.params.slug);

											if (router.app.weight) {
													url += '?weight=' + router.app.weight;
											}

											hijax('get', url, null, function (data) {
															var items = JSON.parse(data).items;
															if (items.length) {
																	router.app.productGroupings = JSON.parse(data).items;
															}
													}
											);
									}

									next();
							});
					}
			},
			{
					name: 'products',
					path: '/:slug/products',
					component: Products,
					beforeEnter: function (to, from, next) {
							main(function () {
									var values;
									window.scrollTo(0, 0);
									console.log('/:slug/products');

									router.app.displayCart = false;
									router.app.displayMenu = false;
									router.app.displayCheckout = false;
									router.app.displayPayment = false;
									router.app.displayOrderConfirmation = false;
									router.app.displaySearch = false;
									router.app.displaySalesPage = false;

									if (to.params.slug == 'all') {
											router.app.displaySearch = true;
									}

									if (!router.app.chosenProductType) {
											values = window.location.pathname.split('/');

											router.app.chosenProductType = restoreProductTypeName(values[1]); // Also restores info link
											router.app.chosenProductTypeSystem = values[1];

											if (!router.app.chosenProductTypeSystem) {
													router.app.chosenProductType = to.params.slug;
													router.app.chosenProductTypeSystem = to.params.slug;
											}
									}

									getProductTypesGroupsFromBackend(to.query);
									getOptionGroupsFromBackend(to.query);
									setSortOption(to.query);

									next();
								});
					}
			},
			{
					name: 'gproducts',
					path: '/:slug/:grouping/products',
					component: Products,
					beforeEnter: function (to, from, next) {
							main(function () {
									window.scrollTo(0, 0);
									console.log('/:slug/:grouping/products');

									router.app.displayCart = false;
									router.app.displayMenu = false;
									router.app.displayCheckout = false;
									router.app.displayPayment = false;
									router.app.displayOrderConfirmation = false;
									router.app.displaySearch = false;
									router.app.displaySalesPage = false;
									router.app.productGroupings = null;

									restoreProductType();
									getOptionGroupsFromBackend(to.query);
									getProductGroupsFromBackend();
									setSortOption(to.query);

									next();
							});
					}
			},
			{
					name: 'orderconfirmation',
					path: '/order/:slug',
					beforeEnter: function (to, from, next) {
							main(function () {
									var url;
									window.scrollTo(0, 0);

									router.app.displayCart = false;
									router.app.displayMenu = false;
									router.app.displayCheckout = false;
									router.app.displayPayment = false;
									router.app.displayOrderConfirmation = true;
									router.app.displaySearch = false;
									router.app.displaySalesPage = false;

									url = window.urlOrderGet.replace('FIXME', to.params.slug);
									hijax('get', url, null, function (data) {
													var order = JSON.parse(data);
													router.app.orderSummary = order.item;
											}
									);
							});
					}
			}
	]
});

router.afterEach(function (to, from) {
	restoreWeightParam(to.query);
	router.app.getPriceChange();
	router.app.displayMenu = false;

	if (from.name && from.name.match(/products/) && to.name.match(/products/)) {
			restoreQueryParams(to.query);
			restoreInputFilters();
			getProductsFromBackend();
			getProductGroupsFromBackend();
			syncDisabledOptions();
			getOptionGroupsFromBackend(to.query);
			setSortOption(to.query);
	} else if (to.query) {
			if (to.query.producttypes) {
					filters.push({
							'id':'producttypes',
							'slug':to.query.producttypes,
							'name':to.query.producttypes,
					});
			}
	} else {
			restoreQueryParams(to.query);
			restoreInputFilters();
			getProductsFromBackend();
			syncDisabledOptions();
			getOptionGroupsFromBackend(to.query);
			setSortOption(to.query);
	}

	getProductTypesGroupsFromBackend(to.query);
	router.app.updateRelatedProducts();
});

const app = new Vue({
	el: '#app',

	router: router,
	components: {
		'autocomplete': Autocomplete,
		'range-slider': RangeSlider
	},

	data: {
			maxWeight: 40,
			aborted: false,
			chosenProducts: [],
			chosenProductsRelationsRequirements: [],
			chosenProductsRelationsRecommendations: [],
			cartIds: [],
			cartCurrency: '',
			shippingCost: 0.0,
			priceExclShipping: 0,
			priceExclShippingDiscount: 0,
			priceExclShippingWithDiscount: 0,
			priceInclShipping: 0,
			priceInclShippingWithDiscount: 0,
			priceDiscountApplied: null,
			priceDiscountErrorMessage: null,
			promoCode: '',
			chosenFilters: [],
			chosenFiltersProducttypes: [],
			chosenFilterProducts: null,
			chosenGroupedProducts: {},
			chosenMapkitFilters: {},
			chosenProductType: null,
			chosenProductTypeSystem: null,
			chosenProductTypeInfoLink: null,
			chosenProductTypeRelations: null,
			chosenProductTypeHelpName: null,
			chosenProductTypeHelpDescription: null,
			chosenProductTypeHelpSections: null,
			chosenProductGrouping: null,
			chosenProductGroupingName: null,
			chosenProductGroupingExternalLink: null,
			hijaxers: {},
			noMoreProducts: true,
			deliveryOptions: [],
			disabledOptions: [],
			acceptTos: false,
			acceptTosFailedValidation: false,
			displayHelp: false,
			displayTerms: false,
			displayCart: false,
			displayMenu: false,
			displayCheckout: false,
			displayPayment: false,
			displaySuccess: false,
			displaySendListForm: false,
			displayPriceChange: false,
			displayOrderConfirmation: false,
			displaySearch: false,
			displaySalesPage: false,
			priceChange: {},
			loading: false,
			displayAside: false,
			displayTT: true,
			fieldFocused: false,
			formErrors: [],
			editWeight: false,
			// form_first_name: 'Arne',
			// form_last_name: 'Arnesson',
			// form_email: 'arne@arnesson.se',
			// form_share_email: 'beata@arnesson.se',
			// form_phone: '0701234567',
			// form_organisation_nr: '123456-1234',
			// form_organisation_name: 'Arnessons Gräv',
			// form_adress: 'Arnes gata 1',
			// form_zip: '12345',
			// form_city: 'Ankeborg',
			form_first_name: '',
			form_last_name: '',
			form_email: '',
			form_share_email: '',
			form_phone: '',
			form_organisation_nr: '',
			form_organisation_name: '',
			form_adress: '',
			form_zip: '',
			form_city: '',
			form_invoice_first_name: '',
			form_invoice_last_name: '',
			form_invoice_email: '',
			form_invoice_phone: '',
			form_invoice_organisation_nr: '',
			form_invoice_organisation_name: '',
			form_invoice_adress: '',
			form_invoice_zip: '',
			form_invoice_city: '',
			form_pdf_invoice: 0,
			form_delivery_method_id: 1,
			form_comment: null,
			form_accept_copy: null,
			form_tradeshow: null,
			form_tradeshowOptions: [],
			form_campaign_answer: '',
			success: false,
			shared_key_url: null,
			shared_key_url_to: null,
			optionGroups: null,
			optionValues: {},
			optionWCValues: [],
			orderSummary: [],
			orderNumber: null,
			choiceOpen: false,
			products: null,
			productGroupings: null,
			productFilters: {},
			productTypes: null,
			productCategories: {},
			productTypesGroups: {},
			productsTotal: null,
			productsLimit: 20,
			productsListingLimit: 5,
			optionsLimit: 999,
			recentProductChosen: null,
			recentProductChosenTypeSystem: null,
			makes: {},
			models: {},
			weight: 0,
			weightEnabled: true,
			fromOpenCart: false,
			promoClosed: true,
			availabiltyCount: 1,
			vatPercentage: 0,
			vatCost: 0.0,
			sortby: '',
			banners: {},
			saleProducts: {},
			campaigns: {},
	},
	computed: {
			optionsSet: function () {
					var k, os = {}, i = router.app.optionWCValues.length;

					os.limit = router.app.optionsLimit;

					if (router.app.chosenProductGrouping) {
							os.grouping = router.app.chosenProductGrouping;
					}

					while (i--) {
							os[router.app.optionWCValues[i]] = '*';
					}

					for (k in router.app.optionValues) {
							if (router.app.optionValues.hasOwnProperty(k) && router.app.optionValues[k] !== '' && router.app.optionValues[k] !== false) {
									os[k] = router.app.optionValues[k];
							}
					}

					if (router.app.weight) {
							os.weight = parseFloat(router.app.weight);
					}

					return os;
			},
			weight_pretty: function () {
					if (router.app.weight < 1) {
							return '';
					}
					return window.prettyw(Math.round(router.app.weight * 10) / 10);
			},
			sum: function () {
					var i = router.app.chosenProducts.length,
							s = 0,
							sc = 0;

					while (i--) {
							s += parseInt(router.app.chosenProducts[i]['pretty_price'].replace(/[\.\s]/g, ''), 10);
					}

					sc = parseInt(router.app.shippingCost.replace(/[\.\s]/g, ''), 10);

					s = s + sc;

					return window.pretty(s);
			}
	},
	methods: {
			resetToStep1: function () {
					// Cancel all ongoing requests
					for (var i in router.app.hijaxers) {
							router.app.hijaxers[i].abort();
							delete router.app.hijaxers[i];
					}
					router.app.hijaxers = {};

					router.app.loading = false;
					router.app.products = null;
					router.app.chosenProductType = null;
					router.app.chosenProductTypeSystem = null;
					router.app.chosenProductTypeRelations = null;
					router.app.chosenProductTypeHelpName = null;
					router.app.chosenProductTypeHelpDescription = null;
					router.app.chosenProductTypeHelpSections = null;
					router.app.optionGroups = null;
					router.app.optionValues = {};
					app.chosenGroupedProducts = {};
					app.weight = 0;

					router.push('/');

					return false;
			},

			resetToStep2: function () {
					var queryParams = {};
					if (router.app.weight) {
							queryParams.weight = router.app.weight;
					}
					router.push({
							name: 'groupings',
							params: {slug: router.app.chosenProductTypeSystem},
							query: router.app.weight ? {weight: router.app.weight} : {}
					});
					return false;
			},

			scrollToTop: function () {
					window.scrollTo(0,0);
			},

			togglePriceChange: function () {
					router.app.displayPriceChange = !router.app.displayPriceChange;
					/* only update products if already loaded */
					if (router.app.products != null)
							getProductsFromBackend();
			},

			getPriceChange: function () {
					hijax('get',
							window.urlPriceChange,
							null, function (data) {
									var jsonData = JSON.parse(data);
									router.app.priceChange = jsonData.item;
							}
					);
					if (router.app.priceChange.changes_available)
							return true;
					else
							return false;
			},

			getDeliveryOptions: function () {
					hijax('get',
							window.urlDeliveryOptions,
							null, function (data) {
									var options = JSON.parse(data).options;
									if (options.length) {
											router.app.deliveryOptions = options;
									}
							}
					);
			},

			setSearchType: function (selected) {
					var elm,
							spoken,
							system;

					elm = selected.target;
					while (!elm.dataset.title) {
							elm = elm.parentNode;
					}

					spoken = elm.dataset.title;
					system = elm.dataset.name;

					router.app.chosenProductType = spoken;
					router.app.chosenProductTypeSystem = system;
					router.app.chosenProductGroupingName = null;
					router.app.chosenProductGrouping = null;

					if (typeof(elm.dataset.v) != 'undefined') {
							router.app.chosenProductType = 'all';
							router.app.chosenProductGroupingName = spoken;
							router.app.chosenProductGrouping = elm.dataset.v ? elm.dataset.v : null;
					}

					var os = router.app.optionsSet;

					for (const [key, value] of Object.entries(os)) {
							if (key != 'search') {
									delete router.app.optionValues[key];
									delete os[key];
							}
					}

					router.push({name: 'products', params: {slug: 'all'}, query: os}).catch(err => {
					    // Ignore the vue error regarding  navigating to the page they are already on.
					    if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
						      // But print all other errors to the console
						      logError(err);
					    }
				  });

					getOptionGroupsFromBackend(router.app.optionValues);

			},

			setProductType: function (selected) {
					var i,
							url,
							elm,
							spoken,
							system,
							link,
							relations,
							helpName,
							helpDescription,
							helpSections;

					elm = selected.target;
					while (!elm.dataset.title) {
							elm = elm.parentNode;
					}

					spoken = elm.dataset.title;
					system = elm.dataset.name;
					link = elm.dataset.link;

					// FIXME: So ugly...
					i = router.app.productTypes.length;
					while (i--) {
							if (router.app.productTypes[i].name == system) {
									relations = {
											'requirements': router.app.productTypes[i].requirements,
											'recommendations': router.app.productTypes[i].recommendations
									};
									helpName = router.app.productTypes[i].help_sections.name;
									helpDescription = router.app.productTypes[i].help_sections.description;
									helpSections = router.app.productTypes[i].help_sections.items;
							}
					}

					if (relations['requirements'].length < 1)
							relations = 0;

					router.app.chosenProductType = spoken;
					router.app.chosenProductTypeSystem = system;
					router.app.chosenProductTypeInfoLink = link;
					router.app.chosenProductTypeRelations = relations;
					router.app.chosenProductTypeHelpName = helpName;
					router.app.chosenProductTypeHelpDescription = helpDescription;
					router.app.chosenProductTypeHelpSections = helpSections;

					url = window.urlProductGroupings.replace('FIXME', router.app.chosenProductTypeSystem);

					if (router.app.weight) {
							url += '?weight=' + router.app.weight;
					}

					hijax('get', url, null, function (data) {
									var items = JSON.parse(data).items;
									if (items.length) {
											// groupings exists, route to groupings.
											router.app.productGroupings = JSON.parse(data).items;
											router.push({
													name: 'groupings',
													params: {slug: router.app.chosenProductTypeSystem},
													query: router.app.weight ? {weight: router.app.weight} : {}
											});
									} else {
											// no groupings found, go straight to products route.
											router.app.productGroupings = false;
											setProductGrouping(router.app.chosenProductTypeSystem);
									}
							}
					);

					router.app.recentProductChosen = null;
			},

			setProductGrouping: function (chosenProductTypeSystem, option_group_name, title, base) {
					var cpg = title ? title : base;
					var cpgp = base;

					router.app.optionWCValues = [];

					setProductGrouping(chosenProductTypeSystem, cpg, cpgp, base);
			},

			setProductGroupingSearch: function (e) {
				e.preventDefault()
				setProductGroupingSearch(e);
			},

			setProductSearch: function (e) {
				e.preventDefault();
				setProductSearch(e);
			},

			cleanSearch: function (obj) {
					router.app.choiceOpen = false;
					var val = obj.target.value, os;

					blurHilightedField();

					if (val != router.app.optionValues[obj.target.id]) {
							Vue.set(router.app.optionValues, obj.target.id, val);

							os = router.app.optionsSet;
							delete os.limit;

							if (router.app.chosenProductGrouping) {
									router.push({name: 'gproducts', query: os});
							} else {
									router.push({name: 'products', query: os});
							}
					}
			},

			searchAutocompleteUrl: function() {
				var paurl = '/searchac';
				return paurl;
			},

			filterProducts: function (form) {
					var os = router.app.optionsSet;
					delete os.limit;

					if (router.app.chosenProductGrouping) {
							router.push({name: 'gproducts', query: os});
					} else {
							router.push({name: 'products', query: os});
					}
			},

			loadMoreProducts: function (product_type, limit) {
					getProductsFromBackend((router.app.products ? router.app.products.length : null), product_type, limit);
			},

			sortProducts: function (obj) {
					var val = obj.target.value, os;

					if (val != router.app.optionValues[obj.target.id]) {
							Vue.set(router.app.optionValues, obj.target.id, val);

							os = router.app.optionsSet;
							delete os.limit;

							if (router.app.chosenProductGrouping) {
									router.push({name: 'gproducts', query: os});
							} else {
									router.push({name: 'products', query: os});
							}
					}
			},

			setOption: function (val, obj) {
					var i, os;

					if (val && val.name) {
							Vue.set(router.app.optionValues, obj.id, val.name);
					} else {
							Vue.set(router.app.optionValues, obj.id, '');
					}

					blurHilightedField();

					os = router.app.optionsSet;
					delete os.limit;
					delete os.grouping;

					if (router.app.displaySearch) {
							router.push({name: 'products', params: {slug: 'all'}, query: os});
					} else if (router.app.chosenProductGrouping) {
							router.push({name: 'gproducts', query: os});
					} else {
							router.push({name: 'products', query: os});
					}
			},

			cleanOption: function (obj) {
					router.app.choiceOpen = false;
					var val = obj.target.value, os;

					blurHilightedField();

					if (val != router.app.optionValues[obj.target.id]) {
							Vue.set(router.app.optionValues, obj.target.id, val);

							os = router.app.optionsSet;
							delete os.limit;
							delete os.grouping;

							if (router.app.displaySearch) {
									router.push({name: 'products', params: {slug: 'all'}, query: os});
							} else if (router.app.chosenProductGrouping) {
									router.push({name: 'gproducts', query: os});
							} else {
									router.push({name: 'products', query: os});
							}
					}
			},

			addProductToCart: function (product, product_id) {
					var i, id;

					if (!router.app.chosenProducts) {
							router.app.chosenProducts = [];
					}

					if (!router.app.cartIds) {
							router.app.cartIds = [];
					}

					// Use direct link or index
					if (product_id) {
							id = product_id;
					} else {
							id = router.app.products && router.app.products.length ? router.app.products[product.target.dataset.i].id : product.id;
					}

					if (!product_id) {
							router.app.cartIds.push(id);

							hijax('post', window.urlCartItems.replace('FIXME', id), 'csrf', function (data) {
									var jsonData = JSON.parse(data);
									if (jsonData.code && jsonData.code == 404) {
											alert('The following product was not found: \n' + jsonData.product);
									} else {
											router.app.chosenProducts.push(jsonData.item);
											router.app.updateRelatedProducts();
											router.app.updateCart("add_to_cart", [jsonData.item]);
									}
							});
					}
					router.app.recentProductChosen = router.app.products && router.app.products.length ? router.app.products[product.target.dataset.i] : product;
					router.app.recentProductChosenTypeSystem = router.app.chosenProductTypeSystem;

					// Remove shared cart message when adding or removing from cart
					router.app.fromOpenCart = false;
			},

			trackAnalytics: function (action, data) {
					// check if fb and google analytics has loaded
					if (typeof fbq === 'function') {
							// create generic array for items in cart
							var gaitems = [];
							var fbitems = [];
							for(var key in data) {
									gaitems.push({
											item_id: data[key].article,
											item_name: data[key].name,
											coupon: router.app.promoCode,
											index: parseInt(key),
											price: parseInt(vatPrice(data[key].pretty_price).replace(' ', '')), // Price including VAT
											quantity: 1,
									});
									fbitems.push({
											id: data[key].article,
											quantity: 1,
									});
							}
							// Specific add to cart event
							if (action == 'add_to_cart') {
									dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
									dataLayer.push({
										event: action,
										ecommerce: {
											currency: router.app.cartCurrency,
											value: parseInt(router.app.priceInclShipping.replace(' ', '')),  // Price including VAT
											items: gaitems
										}
									});
									fbq('track', 'AddToCart', {
											currency: router.app.cartCurrency,
											value: parseInt(data[0].pretty_price.replace(' ', '')),
											content_type: 'product',
											contents: fbitems
									});
							}
							// Specific remove from cart event
							if (action == 'remove_from_cart') {
								dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
								dataLayer.push({
									event: action,
									ecommerce: {
										currency: router.app.cartCurrency,
										value: parseInt(router.app.priceInclShipping.replace(' ', '')),  // Price including VAT
										items: gaitems
									}
								});
							}
							// Specific purchase event
							if (action == 'purchase') {
									dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
									dataLayer.push({
										event: action,
										ecommerce: {
											currency: router.app.cartCurrency,
											transaction_id: router.app.orderNumber,
											value: parseInt(router.app.priceInclShipping.replace(' ', '')),  // Price including VAT
											coupon: router.app.promoCode,
											items: gaitems
										}
									});
									fbq('track', 'Purchase', {
											currency: router.app.cartCurrency,
											value: parseInt(router.app.priceInclShipping.replace(' ', '')),
											num_items: fbitems.length,
											content_type: 'product',
											contents: fbitems
									});
							}
							// Generic checkout analytics
							var genericEvents = ['view_cart', 'begin_checkout', 'add_shipping_info', 'add_payment_info'];
							if (genericEvents.includes(action)) {
									dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
									dataLayer.push({
										event: action,
										ecommerce: {
											currency: router.app.cartCurrency,
											value: parseInt(router.app.priceInclShipping.replace(' ', '')),  // Price including VAT
											items: gaitems
										}
									});
									var fbaction = action;
									var track = 'track';
									if (action == 'add_payment_info') {
											fbaction = 'AddPaymentInfo';
									} else if (action == 'begin_checkout') {
											fbaction = 'InitiateCheckout';
									} else {
											track = 'trackCustom';
									}
									fbq(track, fbaction, {
											currency: router.app.cartCurrency,
											value: parseInt(router.app.priceInclShipping.replace(' ', '')),
											contents: fbitems
									});
							}

							console.log('Analytics fired - ' + action);
					}
			},

			displayNone: function () {
				router.app.displayCheckout = false;
				router.app.displayCart = false;
				router.app.displayPayment = false;
				router.app.displaySuccess = false;
			},

			toggleCart: function () {
				var newval = !router.app.displayCart;
				router.app.displayNone();
				router.app.displayCart = newval;
				router.app.recentProductChosen = null;
				// Make sure to always scroll to the top
				document.getElementById('app').scrollTo(0,0);
				router.app.checkPromoToggle();
				if (router.app.displayCart == true && router.app.chosenProducts.length > 0) {
					router.app.trackAnalytics('view_cart', router.app.chosenProducts);
				}
			},

			toggleMenu: function () {
				var newval = !router.app.displayMenu;
				router.app.displayMenu = newval;
				// Make sure to always scroll to the top
				document.getElementById('app').scrollTo(0,0);
			},

			toggleCheckout: function () {
				var newval = !router.app.displayCheckout;
				router.app.displayNone();
				router.app.displayCheckout = newval;
				router.app.checkPromoToggle();
				router.app.trackAnalytics('begin_checkout', router.app.chosenProducts);
			},

			togglePayment: function () {
				var newval = !router.app.displayCheckout;
				router.app.displayNone();
				router.app.displayPayment = true;
				router.app.checkPromoToggle();
				router.app.trackAnalytics('add_shipping_info', router.app.chosenProducts);
			},

			toggleSuccess: function () {
				var newval = !router.app.displayCheckout;
				router.app.displayNone();
				router.app.displaySuccess = true;
				router.app.trackAnalytics('add_payment_info', router.app.chosenProducts);
				router.app.trackAnalytics('purchase', router.app.chosenProducts);
			},

			removeProductFromCart: function (selected) {
					var id = selected.target.dataset.id,
							article = parseInt(selected.target.dataset.article, 10),
							name = selected.target.dataset.name,
							price = selected.target.dataset.pretty_price.replace(' ', '');
					if (isNaN(article)) {
							article = selected.target.dataset.article;
					}
					router.app.recentProductChosen = null;

					for (var i = 0; i < router.app.cartIds.length; i++) {
						if (router.app.cartIds[i] == article) {
							router.app.cartIds.splice(i, 1);
							break;
						}
					}

					hijax('delete', window.urlCartItems.replace('FIXME', id), null, function () {
						for (var i = 0; i < router.app.chosenProducts.length; i++) {
							if (router.app.chosenProducts[i].designation.trim() == selected.target.parentNode.querySelector('.var').textContent.trim()) {
								router.app.chosenProducts.splice(i, 1);
								break;
							}
						}

						// START GOOGLE ANALYTICS EVENT TRACKING
						var removedProduct = [];
						removedProduct.push({
							article: article,
							name: name,
							pretty_price: price
						});
						// END GOOGLE ANALYTICS EVENT TRACKING

						router.app.updateRelatedProducts();
						router.app.updateCart("remove_from_cart", removedProduct);

						// Remove shared cart message when adding or removing from cart
						router.app.fromOpenCart = false;
					});

					return false;
			},

			emptyCart: function () {
					hijax('delete', window.urlCart, null, function () {
							router.app.chosenProducts = [];
							router.app.cartIds = [];
							router.app.updateRelatedProducts();
							router.app.updateCart();
					});
					return false;
			},

			toggleRow: function (selected) {
					var elm = selected.target;
					while (elm.nodeName != 'TR') {
							elm = elm.parentNode;
					}
					elm.classList.toggle('collapsed');
			},

			toggleCollapsed: function (selected) {
					var elm = selected.target;
					elm.parentElement.classList.toggle('open');
					elm.nextElementSibling.classList.toggle('collapsed');
			},

			checkPromoToggle: function () {
					if (router.app.promoCode.length) {
						router.app.promoClosed = false;
					} else {
						router.app.promoClosed = true;
					}
			},

			setCurrentOption: function (val, obj) {
					router.app.choiceOpen = false;
					router.app.currentOption = obj.id;
			},

			clearField: function (btn, fieldId) {
					if (!fieldId) {
							var wrapper = btn.target.parentNode;
							if (wrapper.className.indexOf('fld-wrap') === -1) {
									wrapper = wrapper.parentNode;
							}
							var field = wrapper.querySelector('.autocomplete-input');
					}
					var filterId = fieldId ? fieldId : field.id;
					// This must work when nav back
					var target = document.getElementById(filterId);
					if (!fieldId) {
							this.setOption(null, field);
					}
					if (target) {
							target.value = '';
							target._value = null;
							target.classList.remove('set');
							// IMPORTANT - clears the autocomplete input
							// Loop through the filter elements
							this.$children.forEach(function(e) {
									if (e.id == target.id) {
											e.clearInput();
									}
							});
					}
					if (fieldId == 'model') {
							this.$children[1].clearInput();
					} else if (fieldId == 'make') {
							this.resetToStep1();
					}
			},

			clearSearch: function () {
					delete router.app.optionsSet['search'];

					router.app.chosenProductType = 'all';
					router.app.chosenProductTypeSystem = 'all';
					router.app.chosenProductGroupingName = null;
					router.app.chosenProductGrouping = null;

					var os = router.app.optionsSet;

					delete os.limit;

					router.push({name: 'products', params: {slug: 'all'}});

					var search = document.getElementById('search');
					search.value = '';
					this.$children.forEach(function(e) {
							if (e.id == search.id) {
									e.clearInput();
							}
					});
			},

			hilightField: function (fld) {
					router.app.choiceOpen = true;
					router.app.fieldFocused = true;

					if (router.app.optionValues[fld.target.id]) {
							fld.target.value = router.app.optionValues[fld.target.id];
					}

					var i, existing, wrapper = fld.target.parentNode.parentNode;

					// This solves text not being selected on focus in Safari
					// and Chrome.
					fld.target.onmouseup = function () {
							return false;
					};

					if (fld.target.select) {
							fld.target.focus();
							fld.target.select();
					} else {
							fld.target.selectionStart = 0;
							fld.target.selectionEnd = fld.target.value.length;
					}

					existing = document.querySelectorAll('.is-field-hilighted');
					if (existing) {
							i = existing.length;
							while (i--) {
									existing[i].classList.remove('is-field-hilighted');
									existing[i].style.zIndex = '';
							}
					}
					wrapper.classList.add('is-field-hilighted');
					wrapper.style.zIndex = z++;
					setTimeout(function () {
							window.scrollTo(0, 0);
					}, 249);

					return false;
			},

			blurField: function (clicked) {
					router.app.fieldFocused = false;
					clicked.target.parentNode.classList.remove('is-field-hilighted');
			},

			sendListForm: function () {
					if (!router.app.acceptTos)
							return false;
					sendFormToBackend(window.urlProspect, prepareFormData());
					return true;
			},

			gotoRelatedProductType: function (clicked) {
					router.app.setProductType(clicked);
			},

			updateRelatedProducts: function () {
					var recommendations = [],
							requirements = [];

					router.app.chosenProducts.forEach(function(e, i, a) {
							if (e.requirements.length) {
									e.requirements.forEach(function(f, j, b) {
											requirements.push(f);
									});
							}
							if (e.recommendations.length) {
									e.recommendations.forEach(function(f, j, b) {
											recommendations.push(f);
									});
							}
					});

					function uniq(e, i, a) {
							var found = a.findIndex(function(f, j, b) {
									return e.related_type.name === f.related_type.name;
							});
							return found === i;
					};

					function notInCart(e, i, a) {
							return router.app.chosenProducts.findIndex(function(f, j, b) {
									return e.related_type.name === f.product_type;
							}) === -1;
					};

					requirements = requirements.filter(uniq).filter(notInCart);
					recommendations = recommendations.filter(uniq).filter(notInCart);

					router.app.chosenProductsRelationsRequirements = requirements;
					router.app.chosenProductsRelationsRecommendations = recommendations;
			},

			updateCart: function (action, actionItems) {
				hijax('get', window.urlCart,
					null, function (data) {
							var cart = JSON.parse(data);
							router.app.validateCoupon();
							router.app.cartCurrency = cart.item.pretty_currency;
							router.app.vatPercentage = parseInt(cart.item.vat_percentage);
							router.app.shippingCost = cart.item.shipping_cost;
							router.app.priceExclShipping = cart.item.price_excl_shipping;
							router.app.priceInclShipping = vatCheck(cart.item.price_incl_shipping);
							router.app.chosenProducts = router.app.chosenProducts.sort( (a, b) => {
								return parseInt(a.pretty_price) > parseInt(b.pretty_price) ? -1 : 1;
							});
							if ((action == "add_to_cart") || (action == "remove_from_cart")) {
								router.app.trackAnalytics(action, actionItems);
							}
					}
				);
			},

			/* Return true if given product type is required by any other product */
			productIsRequired: function(product_type) {
					var found = false;

					router.app.chosenProducts.forEach(function(e, i, a) {
							if (e.requirements.length) {
									e.requirements.forEach(function(f, j, b) {
											if (f.related_type.name == product_type) {
													found = true;
											}
									});
							}
					});

					return found;
			},

			sendSpecForm: function () {
					var url,
							os = router.app.optionsSet,
							q = [],
							i;

					if (!router.app.acceptTos)
							return false;

					for (i in os) {
							if (os.hasOwnProperty(i)) {
									q.push(i + '=' + encodeURIComponent(os[i]));
							}
					}
					url = window.urlSpec.replace('FIXME', router.app.chosenProductTypeSystem) + '?' + q.join('&');
					sendFormToBackend(url, prepareFormData());
					return true;
			},

			sendCartToEmail: function () {
					if (!router.app.acceptTos) {
						router.app.acceptTosFailedValidation = true;
						return false;
					}

					router.app.acceptTosFailedValidation = false

					hijax('post', window.urlShareCart, 'share_email=' + router.app.form_share_email + '&comment=' + encodeURIComponent(router.app.form_comment), function (res) {
							router.app.formErrors = [];
							router.app.success = true;
							window.scrollTo(0, 0);
							router.app.form_share_email = null;
							router.app.form_comment = null;
							var response = JSON.parse(res);
							router.app.shared_key_url = response.shared_key_url
							router.app.shared_key_url_to = response.shared_key_url_to
					}, function (code, res) {
							var errors = JSON.parse(res), fields = [];
							if ('share_email' in errors) {
									fields.push('share_email_required');
							}
							router.app.formErrors = fields;
					});
					return true;
			},


			// Translate strings not available at render (like bound variables)
			jstrans: function(string) {
					return gettext(string)
			},

			availability: function(status) {
				switch (status) {
					case 1:
						return this.jstrans(lessThanOneWeek);
					case 2:
						return this.jstrans(lessThanTwoWeek);
					default:
						return this.jstrans(moreThanTwoWeek);
				}
			},

			formatDecimalNumber: function(floatNumber) {
				var num = floatNumber.split('.');
				if (num[1] == 0) {
					return num[0];
				} else {
					return floatNumber.slice(0, floatNumber.length -2);
				}
			},

			validateCoupon: function() {
				router.app.priceExclShippingDiscount = 0
				router.app.priceExclShippingWithDiscount = 0
				router.app.priceInclShippingWithDiscount = 0
				router.app.priceDiscountApplied = null
				router.app.priceDiscountErrorMessage = null

				if (router.app.promoCode) {
					hijax('post', window.urlPromocode, 'code=' + router.app.promoCode,

						function (res) {
							var response = JSON.parse(res)
							router.app.priceDiscountApplied = true
							router.app.priceExclShippingDiscount = response.discount
							router.app.priceExclShippingWithDiscount = response.new_price
							router.app.priceInclShippingWithDiscount = vatCheck(response.new_price)
						},

						function (error, errorMessage) {
							console.log('error', error, errorMessage);
							router.app.priceDiscountApplied = false
							if (errorMessage.length) {
								router.app.priceDiscountErrorMessage = errorMessage
							}
							router.app.priceExclShippingDiscount = 0
							router.app.priceExclShippingWithDiscount = 0
							router.app.priceInclShippingWithDiscount = 0
						}
					);
				}
			},

			setAvailabiltyCount: function() {
				const ac = [];
				for (var i = 0; i < router.app.chosenProducts.length; i++) {
					ac.push(router.app.chosenProducts[i].availability);
				}
				const uniqueAC = Array.from(new Set(ac));
				router.app.availabiltyCount = uniqueAC.length;
			},

			autoPopulatePaymentFormData: function() {
				var fields = [
						'organisation_nr', 'organisation_name', 'adress', 'zip', 'city',
						'first_name', 'last_name', 'email', 'phone',
					],
					i = fields.length;

				while (i--) {
						if (router.app['form_' + fields[i]]) {
								if (router.app['form_invoice_' + fields[i]] == '') {
										router.app['form_invoice_' + fields[i]] = router.app['form_' + fields[i]];
								}
						}
				}

				return true;
			},

			deliveryMethodChange: function() {
					hijax('post', window.urlDeliveryOptions, 'delivery_method_id=' + router.app.form_delivery_method_id,

						function (res) {
							var response = JSON.parse(res);
							router.app.updateCart();
						},

						function (res) {
							console.log('error', res);
						}
					);
			},

			gotoPayment: function(url, data) {

				if (router.app.displayCheckout) {

					hijax('post', window.urlCart, prepareFormData(true),

						function (res) {
							router.app.success = true;
							window.scrollTo(0, 0);
							router.app.formErrors = [];
							router.app.autoPopulatePaymentFormData();
							router.app.togglePayment();
						},

						function (code, res) {
							var errors = JSON.parse(res), fields = [];

							if ('organisation_nr' in errors) {
								fields.push('organisation_nr_required');
							}

							if ('organisation_name' in errors) {
								fields.push('organisation_name_required');
							}

							if ('adress' in errors) {
								fields.push('adress_required');
							}

							if ('city' in errors) {
								fields.push('city_required');
							}

							if ('zip' in errors) {
								fields.push('zip_required');
							}

							if ('adress' in errors) {
								fields.push('adress_required');
							}

							if ('email' in errors) {
								fields.push('email_required');
							}

							if ('phone' in errors) {
								fields.push('phone_required');
							}

							if ('first_name' in errors) {
								fields.push('first_name_required');
							}

							if ('last_name' in errors) {
								fields.push('last_name_required');
							}

							router.app.formErrors = fields;
					});
				}
			},

			gotoCheckout: function() {
				router.app.getDeliveryOptions();
				router.app.setAvailabiltyCount();
				router.app.toggleCheckout();
			},

			orderSummaryData: function () {
				var fields = [
						'first_name', 'last_name', 'email', 'phone', 'comment', 'tradeshow', 'accept_copy', 'organisation_nr',
						'organisation_name', 'adress', 'zip', 'city', 'invoice_first_name', 'invoice_last_name', 'invoice_email',
						'invoice_phone', 'invoice_organisation_nr', 'invoice_organisation_name', 'invoice_adress', 'invoice_zip',
						'invoice_city'
					],
					data = [],
					i = fields.length;

				while (i--) {
						if (router.app['form_' + fields[i]]) {
								data[fields[i]] = router.app['form_' + fields[i]];
						}
				}

				data['priceInclShippingWithDiscount'] = router.app.priceInclShippingWithDiscount;
				data['priceInclShipping'] = router.app.priceInclShipping;
				data['orderNumber'] = router.app.orderNumber;

				router.app.orderSummary = data;
			},

			finalizeOrder: function () {

				// if (!router.app.acceptTos)
				// 		return false;

				hijax('post', window.urlCart, prepareFormData(false),

					function (res) {
						router.app.success = true;
						window.scrollTo(0, 0);

						router.app.orderSummaryData();

						router.app.form_first_name = null;
						router.app.form_last_name = null;
						router.app.form_email = null;
						router.app.form_phone = null;
						router.app.form_comment = null;
						router.app.form_organisation_nr = null;
						router.app.form_organisation_name = null;
						router.app.form_adress = null;
						router.app.form_zip = null;
						router.app.form_city = null;
						router.app.form_delivery_method_id = 1;

						router.app.form_invoice_first_name = null;
						router.app.form_invoice_last_name = null;
						router.app.form_invoice_email = null;
						router.app.form_invoice_phone = null;
						router.app.form_invoice_comment = null;
						router.app.form_invoice_organisation_nr = null;
						router.app.form_invoice_organisation_name = null;
						router.app.form_invoice_adress = null;
						router.app.form_invoice_zip = null;
						router.app.form_invoice_city = null;
						router.app.form_pdf_invoice = 0;

						router.app.form_campaign_answer = null;

						router.app.promoCode = '';

						router.app.formErrors = [];
						router.app.emptyCart();

						// TODO: Show confirmation screen
						router.app.toggleSuccess();
					},

					function (code, res) {

						var errors = JSON.parse(res), fields = [];

						if ('invoice_organisation_nr' in errors) {
							fields.push('invoice_organisation_nr_required');
						}

						if ('invoice_organisation_name' in errors) {
							fields.push('invoice_organisation_name_required');
						}

						if ('invoice_adress' in errors) {
							fields.push('invoice_adress_required');
						}

						if ('invoice_city' in errors) {
							fields.push('invoice_city_required');
						}

						if ('invoice_zip' in errors) {
							fields.push('invoice_zip_required');
						}

						if ('invoice_adress' in errors) {
							fields.push('invoice_adress_required');
						}

						if ('invoice_email' in errors) {
							fields.push('invoice_email_required');
						}

						if ('invoice_phone' in errors) {
							fields.push('invoice_phone_required');
						}

						if ('invoice_first_name' in errors) {
							fields.push('invoice_first_name_required');
						}

						if ('invoice_last_name' in errors) {
							fields.push('invoice_last_name_required');
						}

						router.app.formErrors = fields;
					});
			},

			openInteractive: function(newSrc, interactive_url) {
				if (interactive_url != false) {
					document.querySelector('model-viewer').src = '/static/images/buckets/3d/' + newSrc + '.glb';
					document.body.classList.add('interactive');
				}
			},

			onImageLoadFail: function(event, image_url) {
				event.target.src = image_url;
				if (image_url != null) {
					if (image_url.includes('sweeper-roller')) {
						image_url = image_url.replace('sweeper-roller', 'sweeper-roller-alt');
					}
					event.target.parentElement.children[0].srcset = image_url.replace('jpg', 'webp');
					event.target.parentElement.children[1].srcset = image_url;
					event.target.parentElement.children[1].type = 'image/jpeg';
					//event.target.parentElement.parentElement.style = 'pointer-events: none';
					//event.target.parentElement.parentElement.classList.remove('is-clickable');
					//event.target.parentElement.nextElementSibling.nextElementSibling.style = 'display:none';
				}
			},
	}
})
